<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-8">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <h3 class="mb-0">
            {{ lista_campos != "" ? "Editar |" : "Criar |" }} Ponto de Venda
          </h3>
        </div>
        <div class="card-body table-responsive">
          <form action="" class="text-uppercase">
            <div class="form-group row text-uppercase">
              <div class="col-md-2">
                <label class="col-md-12">id:*</label>
                <input
                  required
                  type="number"
                  class="form-control"
                  v-model="form.id"
                  placeholder="Digite o id..."
                />
              </div>
              <div class="col-md-3">
                <label class="col-md-12">Tipo:*</label>
                <select
                  class="form-control p-1"
                  
                  v-model="form.tipo"
                >
                  <option disabled :value="0">selecione um tipo</option>

                  <option :value="1">Moderninha Smart</option>
                  <option :value="2">Moderninha Smart 2</option>
                </select>
              </div>
              <div class="col-md-3">
                <label class="col-md-12">N Série:*</label>
                <input
                  required
                  type="text"
                  class="form-control"
                  v-model="form.n_serie"
                  placeholder="Digite o N de serie..."
                />
              </div>
              <div class="col-md-4">
                <label class="col-md-12">Imei:*</label>
                <input
                  required
                  type="text"
                  class="form-control"
                  v-model="form.imei"
                  placeholder="Digite o imei..."
                />
              </div>
            </div>
            <!-- <div class="form-group row">
              <div class="col-md-4">
                <label class="col-md-12">Pais:*</label>
                <treeselect
                  required=""
                  :multiple="false"
                  :options="[
                    { id: 1, label: 'Brasil' },
                    { id: 28, label: 'Paraguai' },
                  ]"
                  placeholder="Selecione o Pais..."
                  v-model="pais_id"
                  @input="onInput2"
                />
              </div>
              <div class="col-md-4" v-show="pais_id != 28">
                <label class="col-md-12">Estado:*</label>
                <treeselect
                  :multiple="false"
                  :options="lista_estados"
                  placeholder="Selecione o Estado..."
                  v-model="estados_id"
                  @input="onInput"
                />
              </div>
              <div class="col-md-4">
                <label class="col-md-12">Cidade:*</label>
                <treeselect
                  :multiple="false"
                  :options="lista_cidades"
                  placeholder="Selecione a Cidade..."
                  v-model="form.cidade_id"
                  @input="onInput3"
                />
              </div>
            </div> -->

            <div class="row form-group col-md-12">
              <div class="col-md-10 pull-left">
                <div class="md-radio-inline text-uppercase">
                  <label>Status:*</label>
                  <div class="space d-flex">
                    <b-form-radio
                      :inline="true"
                      :value="2"
                      v-model="form.status"
                    >
                      inativo
                    </b-form-radio>
                    <b-form-radio
                      :inline="true"
                      :value="1"
                      v-model="form.status"
                    >
                      ativo
                    </b-form-radio>
                  </div>
                </div>
              </div>
            </div>
     
            <div class="form-group row">
              <div class="col-md-12 text-center">
                <button
                  @click.prevent="
                    confirm(lista_campos == '' ? 'cria' : 'edita')
                  "
                  class="btn btn-primary"
                  :disabled="verif"
                >
                  Salvar
                  <b-spinner
                    v-show="verif"
                    small
                    variant="dark"
                    type="grow"
                    label="Spinning"
                  ></b-spinner>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fireAlert } from "@/core/mixins/alertMessageMixin";

export default {
  mixins: [fireAlert],
  data() {
    return {
      form: {
        id: 0,
        imei: null,
        tipo: 0,
        n_serie: null,
        status: null,
      },

      valmask: "",
    };
  },
  created() {
    this.preenxerCampos();
  },

  computed: {
    lista_campos() {
      return this.$store.state.configEmpresa.lista_campos;
    },
    mensagem_alert() {
      return this.$store.state.maquininha.mensagem_alert;
    },
  },
  methods: {
    async confirm(tipo) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação  ` + tipo + ` um Pdv no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "cria") this.create();
            if (tipo == "edita") this.update();
          }
        },
      });
    },
    async create() {
      this.verif = true;
      await this.$store.dispatch("maquininha/create_maquininha", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "maquininhas",
      });
    },
    async update() {
      this.verif = true;
      await this.$store.dispatch("maquininha/update_maquininha", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "maquininhas",
      });
    },

    preenxerCampos() {
      if (this.lista_campos != "")
        this.form = {
          id: this.lista_campos.id,
          imei: this.lista_campos.imei,
          tipo: this.lista_campos.tipo,
          n_serie: this.lista_campos.n_serie,
          status: this.lista_campos.status,
        };

  

    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>